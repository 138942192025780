.about {
    margin: 16px;
}

.about p {
    margin: 8px 0px;
}

.subscribe {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.subscribe * {
    flex-grow: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.subscribe button {
    margin: 0px;
    background-color: #2c9e4b;
    color: white;
    cursor: pointer;
}
