.content {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    width: 100%;
    height: 90vh;
}

.map-container {
    flex-grow: 1;
    min-width: 500px;
    height: 100%;
    position: relative;
}

.sidebar {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    min-width: 240px;
    max-width: 300px;
    height: 100%;
    left: 0;
    overflow: hidden;
    border-right: 1px solid rgb(0 0 0 / 25%);
}

.sidebar-heading {
    border-bottom: 1px solid #eee;
    padding: 16px 16px;
    margin: 0px;
}

.listings {
    flex-grow: 1;
    overflow-y: scroll;
}

.listings .item {
    border-bottom: 1px solid #eee;
    padding: 16px 16px;
    text-decoration: none;
}

.refreshButton {
    position: absolute;
    top: 10px;
    left: 260px;
    height: 36px;
    width: 36px;
    z-index: 10;
    padding: 0px;
}

.refreshIcon {
    padding: 1px;
}

.marketPopup {
    border-radius: 8px;
}

@media (max-width: 768px) {
    .content {
        flex-direction: column;
        height: 140vh;
    }

    .sidebar {
        flex-grow: 1;
        order: 2;
        max-width: 100%;
    }

    .map-container {
        flex-grow: 2;
        min-width: 0;
        min-height: 80vh;
    }

    .refreshButton {
        left: 365px;
        height: 50px;
        width: 50px;
        padding: 6px;
    }
}
