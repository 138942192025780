.App {
    text-align: center;
}

.App-link {
    color: #61dafb;
}

h1 {
    font-size: 30px;
    font-weight: 500;
    margin: 0px;
}

h2 {
    line-height: 2rem;
    margin: 0px;
}

h3 {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5rem;
    margin: 0px;
}

p {
    font-size: 14px;
    color: #46494d;
    line-height: 1.25rem;
    margin: 0px;
}

body {
    font-family: Verdana, sans-serif;
}

.header {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-basis: 0;
    align-items: flex-end;
    justify-content: space-between;
    height: 80px;
    background-color: #2c9e4b;
    border-bottom: 1px solid #eee;
}

.logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff2b2;
    padding-left: 8px;
}

.nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 24px 32px;
}

.nav a {
    color: white;
    font-size: 18px;
    text-decoration: none;
}
